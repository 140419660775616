<template>
  <div >
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="authentication-verify">

      <div class="search">
        <div class="search-item">
          <div class="name">
            通知途径:
          </div>
          <div class="cont">
            <RadioGroup v-model="noticeWay" @on-change="changeWay">
              <Radio :label="item.id" style="font-size: 14px" v-for="item in sendTypeArr" :key="item.id"><span>{{ item.name }}</span></Radio>
            </RadioGroup>
<!--            <CheckboxGroup v-model="noticeWay" style="font-size: 14px">-->
<!--              <Checkbox label="1" style="font-size: 14px">-->
<!--                <span>微信</span>-->
<!--              </Checkbox>-->
<!--              <Checkbox label="2" style="font-size: 14px">-->
<!--                <span>短信</span>-->
<!--              </Checkbox>-->
<!--            </CheckboxGroup>-->
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            通知类型:
          </div>
          <div class="cont">
            <RadioGroup v-model="noticeType" @on-change="changeType">
              <Radio :label="item.id" style="font-size: 14px" v-for="item in typeArr" :key="item.id"><span>{{ item.name }}</span></Radio>
            </RadioGroup>
          </div>
        </div>

        <div class="search-item" v-if="noticeType && noticeType != 0 && noticeWay == '1'">
          <div class="name">
<!--            通知内容:-->
          </div>
          <div class="notice-style">
<!--            <Input v-model="noticeCont" :maxlength="100" type="textarea" placeholder="Enter something..." style="max-width: 600px" />-->
            <div class="notice-style-item" v-for="item in templateFieldsArr" :key="item.key">
              <p>{{ item.name }}：</p>
              <Input v-model="item.value" placeholder="请输入描述" style="width: 300px" v-if="item.key != 'name'" />
            </div>
          </div>
        </div>
<!--        <div class="search-item" v-if="noticeWay == '0'">-->
<!--          <div class="name">-->
<!--            通知内容:-->
<!--          </div>-->
<!--          <div class="cont">-->
<!--            <Input v-model="noticeCount" :maxlength="100" show-word-limit type="textarea" placeholder="请输入通知内容"  />-->
<!--          </div>-->
<!--        </div>-->
        <div class="search-item">
          <div class="name">
            人员通知:
          </div>
        </div>
        <div class="box">
          <div class="box-tit">
            <p>通知额外人员</p>
            <span @click="addUser(extraUser,'add')">添加人员</span>
          </div>
          <div class="box-cont" v-if="extraUser.length">
            <div v-for="item in extraUser">
              <span>{{ item.user ? item.user.nickname : item.nickname }}({{item.user ? item.user.realname : item.realname}}:{{item.user ? item.user.id : item.id}})</span>
              <Icon type="md-close" @click="deleteData(item,extraUser)" />
            </div>
          </div>
          <div class="box-cont" v-else>
            暂无数据～
          </div>
        </div>
        <div class="box">
          <p class="box-tit">通知师资人员</p>
          <div class="search-item">
            <div class="name">
              审核状态:
            </div>
            <div class="cont">
              <div class="values">
                <div>
                  <Checkbox
                      :indeterminate="statusindeterminate"
                      :value="statuscheckAll"
                      @click.prevent.native="handleCheckAll('status',statusesArr)"><span style="font-size: 14px">全选</span></Checkbox>
                </div>
                <CheckboxGroup v-model="statuscheckAllGroup" @on-change="(data)=>{checkAllGroupChange(data,'status',statusesArr.length)}">
                  <Checkbox :label="item.id" v-for="item in statusesArr" :key="item.id + 'statuses'"><span style="font-size: 14px">{{item.name}}</span></Checkbox>
                </CheckboxGroup>
              </div>
            </div>
          </div>
          <div class="search-item">
            <div class="name">
              技术方向:
            </div>
            <div class="cont">
              <div class="values">
                <div>
                  <Checkbox
                      :indeterminate="techindeterminate"
                      :value="techcheckAll"
                      @click.prevent.native="handleCheckAll('tech',techDirection)"><span style="font-size: 14px">全选</span></Checkbox>
                </div>
                <CheckboxGroup v-model="techcheckAllGroup" @on-change="(data)=>{checkAllGroupChange(data,'tech',techDirection.length)}">
                  <Checkbox :label="item.id" v-for="item in techDirection" :key="item.id + 'tech'"><span style="font-size: 14px">{{item.name}}</span></Checkbox>
                </CheckboxGroup>
              </div>
              <!--            <div class="values">-->
              <!--              <div :class="curTechDirectionIndex == index ? 'active' : ''" v-for="(item,index) in techDirection" :key="item.id + 'techDirection'" @click="changeSearch(index,'curTechDirectionIndex')">{{item.name}}</div>-->
              <!--            </div>-->
            </div>
          </div>
          <div class="search-item">
            <div class="name">
              技术体系:
            </div>
            <div class="cont">
              <div class="values">
                <div>
                  <Checkbox
                      :indeterminate="systemindeterminate"
                      :value="systemcheckAll"
                      @click.prevent.native="handleCheckAll('system',techSystem)"><span style="font-size: 14px">全选</span></Checkbox>
                </div>
                <CheckboxGroup v-model="systemcheckAllGroup" @on-change="(data)=>{checkAllGroupChange(data,'system',techSystem.length)}">
                  <Checkbox :label="item.id" v-for="item in techSystem" :key="item.id + 'tech'"><span style="font-size: 14px">{{item.name}}</span></Checkbox>
                </CheckboxGroup>
              </div>
              <!--            <div class="values">-->
              <!--              <div :class="curTechSystemIndex == index ? 'active' : ''" v-for="(item,index) in techSystem" :key="item.id + 'techSystem'" @click="changeSearch(index,'curTechSystemIndex')">{{item.name}}</div>-->
              <!--            </div>-->
            </div>
          </div>
          <div class="search-item">
            <div class="name">
              含有经验:
            </div>
            <div class="cont">
              <div class="values">
                <div>
                  <Checkbox
                      :indeterminate="workindeterminate"
                      :value="workcheckAll"
                      @click.prevent.native="handleCheckAll('work',workExp)"><span style="font-size: 14px">全选</span></Checkbox>
                </div>
                <CheckboxGroup v-model="workcheckAllGroup" @on-change="(data)=>{checkAllGroupChange(data,'work',workExp.length)}">
                  <Checkbox :label="item.id" v-for="item in workExp" :key="item.id + 'tech'"><span style="font-size: 14px">{{item.name}}</span></Checkbox>
                </CheckboxGroup>
              </div>
            </div>
          </div>
        </div>
        <div class="box" v-if="workcheckAllGroup.length || systemcheckAllGroup.length || techcheckAllGroup.length || statuscheckAllGroup.length">
          <div class="box-tit">
            <p>不接收通知的人员</p>
            <span @click="addUser(removeUserList,'remove')">添加人员</span>
          </div>
          <div class="box-cont" v-if="removeUserList.length">
            <div v-for="item in removeUserList">
              <span>{{ item.user ? item.user.nickname : item.nickname }}({{item.user ? item.user.realname : item.realname}}:{{item.user ? item.user.id : item.id}})</span>
              <Icon type="md-close" @click="deleteData(item,removeUserList)" />
            </div>
          </div>
          <div class="box-cont" v-else>
            暂无数据～
          </div>
        </div>
      </div>
      <Button style="margin:0 20px 0 30px" @click="back">取消</Button>
      <Button type="primary" style="margin:0 20px 0 0" @click="confirmNotice(0)">保存</Button>
      <Button type="primary" @click="confirmNotice(1)">确认通知</Button>
    </div>

    <Modal
        v-model="addUserModal"
        title="添加人员"
        @on-cancel="closeAddUserModal"
        width="1100px"
    >
      <div class="top" >
        <div></div>
        <Input v-model="keyword"
               @on-enter="searchList"
               @on-search="searchList"
               :placeholder="$t('user_search_tip')"
               clearable
               search
               :enter-button="true"
               style="width: 300px"
        />
      </div>
      <div class="table" >
        <Table :columns="columns" :data="data" :loading="loading" @on-select="selectData" @on-select-all="selectData" @on-select-cancel="cancelData" @on-select-all-cancel="cancelData"></Table>
      </div>
      <div class="page" >
        <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
      </div>
      <div slot="footer">
        <Button @click="closeAddUserModal">取消</Button>
        <Button type="primary" @click="confirmAddUser">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import FileSaver from "file-saver";

import Cookies from "js-cookie";
export default {
  name: "authenticationVerify.vue",
  data(){
    return {
      Cookies:Cookies,
      keyword:'',
      columns:[
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '用户ID',
          key:'user_id',
        },
        {
          title: '用户昵称',
          key: 'nickname',
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.nickname);
          }
        },
        {
          title: '姓名',
          key: 'realname',
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.realname);
          }
        },
        {
          title: '手机号码',
          key: 'mobile',
          render:(h,params)=>{
            return h('span', params.row.user && params.row.user.mobile);
          }
        },
        {
          title: '技术方向',
          key: 'tech_direction',
          render:(h,params)=>{
            let data = [];
            for(let name in params.row.tech_direction_arr){
              data.push(params.row.tech_direction_arr[name])
            }
            return h('span', data.join(','));
          }
        },
        {
          title: '技术体系',
          key: 'tech_system',
          render:(h,params)=>{
            let data = [];
            for(let name in params.row.tech_system_arr){
              data.push(params.row.tech_system_arr[name])
            }
            return h('span', data.join(','));
          }
        },
        {
          title: '合作项目',
          key: 'remarks',
          render:(h,params)=>{
            return h('span', params.row.join_project);
          }
        },
      ],
      data:[],
      loading:false,
      total:0,
      page:1,
      pageSize:8,
      curData:{},
      statuses:{},
      techDirection:[],
      techSystem:[],
      workExp:[],
      statusesArr:[],
      curStatusIndex:0,
      curTechSystemIndex:0,
      curTechDirectionIndex:0,
      curWorkExpIndex:0,
      typeList:[
        {
          label:'新任务通知',
          value:1
        }
      ],
      type:1,
      noticeWay:'1',
      noticeType:'2',
      selectDataList:[],
      sendTypeArr:[],
      statuseArr:[],
      typeArr:[],
      templateFields:{},
      templateFieldsArr:[],
      statusindeterminate: false,
      statuscheckAll: false,
      statuscheckAllGroup: [],
      techindeterminate: false,
      techcheckAll: false,
      techcheckAllGroup: [],
      systemindeterminate: false,
      systemcheckAll: false,
      systemcheckAllGroup: [],
      workindeterminate: false,
      workcheckAll: false,
      workcheckAllGroup: [],
      addUserModal:false,
      extraUser:[],
      removeUserList:[],
      addUserType:'',
      noticeCount:'',
      dataId:'',
    }
  },
  created(){
    this.dataId = this.$route.query.id || '';

    this.getTeacherForm();
    this.getForm();
  },
  methods:{
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.api.user.teacherNoticeDetail(params).then((res)=>{
        this.techcheckAllGroup = res.info.tech_direction ? res.info.tech_direction.split(',') : [];
        this.checkAllGroupChange(this.techcheckAllGroup,'tech',this.techDirection.length);
        this.systemcheckAllGroup = res.info.tech_system ? res.info.tech_system.split(',') : [];
        this.checkAllGroupChange(this.systemcheckAllGroup,'system',this.techSystem.length);
        this.statuscheckAllGroup = res.info.verify_status ? res.info.verify_status.split(',') : [];
        this.checkAllGroupChange(this.statuscheckAllGroup,'status',this.statusesArr.length);
        this.workcheckAllGroup = res.info.work_exp ? res.info.work_exp.split(',') : [];
        this.checkAllGroupChange(this.workcheckAllGroup,'work',this.workExp.length);
        this.extraUser = res.toUserList || [];
        this.removeUserList = res.notToUserList || [];
        this.noticeWay = res.info.send_type;
        console.log(this.noticeWay,'this.noticeWaythis.noticeWay')
        this.noticeType = res.info.type;
        this.noticeCount = res.info.message;
        if(this.noticeWay == '1'){
          this.templateFieldsArr = JSON.parse(res.info.message)
        }else if(this.noticeWay == ''){
          this.noticeCount = res.info.message;
        }

      })
    },
    handleCheckAll (name,data) {
      if (this[name + 'indeterminate']) {
        this[name + 'checkAll'] = false;
      } else {
        this[name + 'checkAll'] = !this[name + 'checkAll'];
      }
      this[name + 'indeterminate'] = false;

      if (this[name + 'checkAll']) {
        this[name + 'checkAllGroup'] = data.map((item)=>{
          return item.id;
        });
      } else {
        this[name + 'checkAllGroup'] = [];
      }
      this.isAllCancel();
    },
    checkAllGroupChange (data,name,length) {
      if (data.length === length) {
        this[name + 'indeterminate'] = false;
        this[name + 'checkAll'] = true;
      } else if (data.length > 0) {
        this[name + 'indeterminate'] = true;
        this[name + 'checkAll'] = false;
      } else {
        this[name + 'indeterminate'] = false;
        this[name + 'checkAll'] = false;
      }
      this.isAllCancel();
    },
    isAllCancel(){
      if(!this.workcheckAllGroup.length && !this.systemcheckAllGroup.length && !this.techcheckAllGroup.length && !this.statuscheckAllGroup.length){
        this.removeUserList = [];
      }
    },
    getForm(){
      let params = {
        page:1,
        pageSize:10,
      }
      this.api.user.teacherNoticeList(params).then((res)=>{
        this.sendTypeArr = [];
        for(let name in res.send_types){
          this.sendTypeArr.push({
            name:res.send_types[name],
            id:name
          })
        }
        this.statuseArr = [];
        for(let name in res.statuses){
          this.statuseArr.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.typeArr = [];
        for(let name in res.types){
          this.typeArr.push({
            name:res.types[name],
            id:name
          })
        }
        this.templateFields = res.weixin_template_fields;
        this.changeType();
        if(!this.dataId){
          this.extraUser = res.toUserList || [];
        }

      }).catch((err)=>{
        this.loading = false;

      })
    },
    changeWay(){
      this.noticeType = this.typeArr[0].id;
      // this.noticeCount = '';
    },
    changeType(){
      let templateObj = this.templateFields[this.noticeType];
      this.templateFieldsArr = [];
      for(let name in templateObj){
        this.templateFieldsArr.push({
          name:templateObj[name],
          key:name,
          value:'',
        })
      }
    },
    changeSearch(index,name){
      this[name] = index;
      this.getList();
    },
    getTeacherForm(data){
      this.api.user.userTeacherForm().then((res)=>{
        this.techDirection = [];
        for(let name in res.tech_direction){
          this.techDirection.push({
            id:name,
            name:res.tech_direction[name]
          })
        }
        this.techSystem = [];
        for(let name in res.tech_system){
          this.techSystem.push({
            id:name,
            name:res.tech_system[name]
          })
        }
        this.workExp = [];
        for(let name in res.work_exp){
          this.workExp.push({
            id:name,
            name:res.work_exp[name]
          })
        }
        this.statusesArr = [];
        for(let name in res.statuses){
          this.statusesArr.push({
            id:name,
            name:res.statuses[name],
          })
        }

        if(this.dataId){
          this.getDetail();
        }

        this.getList();
      })
    },
    add(){
      this.$router.push({
        path:'/user/authentication'
      })
    },
    edit(userId,name){
      this.$router.push({
        path:'/user/authentication',
        query:{
          id:userId,
          name:name
        }
      })
    },
    searchList(){
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize,
        site_id:-1,
        is_subscribe:this.noticeWay == '1' ? 1 : 0,
      };
      this.api.user.userTeacherList(params).then((res)=>{
        if(!res.list.length && this.page > 1){
          this.page = this.page - 1;
          this.getList();
          return;
        }
        this.data = res.list;
        this.statuses = res.statuses;
        this.total = Number(res.count);



        this.data.forEach((item)=>{
          let len = this.selectDataList.filter((sItem)=>{
            return sItem.user_id == item.user_id;
          });
          if(len && len.length){
            item._checked = true;
          }
        })

      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },

    selectData(selection,row){
      if(row){
        this.selectDataList.push(row);
      }else{
        this.data.forEach((item)=>{
          let len = this.selectDataList.filter((sItem)=>{
            return sItem.id == item.id;
          }).length;
          if(!len){
            this.selectDataList.push(item);
          }
        })
      }
    },
    cancelData(selection,row){
      if(row){
        let ind = this.selectDataList.findIndex((item)=>{
          return item.id == row.id;
        });
        if(ind != -1){
          this.$delete(this.selectDataList,ind);
        }
      }else{
        this.data.forEach((item)=>{
          let ind = this.selectDataList.findIndex((sItem)=>{
            return sItem.id == item.id;
          });
          if(ind != -1){
            this.$delete(this.selectDataList,ind);
          }
        })
      }
    },
    deleteData(data,arr){
      let ind = arr.findIndex((item)=>{
        return item.id == data.id;
      });
      this.$delete(arr,ind);
      console.log(this.extraUser,'this.extraUserthis.extraUser')
    },
    addUser(data,type){
      if(!this.noticeWay){
        this.$Message.warning('请先选择通知途径～');
        return;
      }
      this.addUserType = type;
      this.addUserModal = true;
      this.selectDataList = JSON.parse(JSON.stringify(data));
      this.page = 1;
      this.getList();
      console.log(this.selectDataList,'this.selectDataList')
    },
    closeAddUserModal(){
      this.addUserModal = false;
    },
    confirmAddUser(data){
      this.addUserModal = false;
      if(this.addUserType == 'add'){
        this.extraUser = JSON.parse(JSON.stringify(this.selectDataList))
      }else{
        this.removeUserList = JSON.parse(JSON.stringify(this.selectDataList))
      }
    },
    back(){
      this.$router.go(-1);
    },
    confirmNotice(status){
      if(!this.noticeWay){
        this.$Message.warning('请选择通知途径～');
        return;
      }

      if(!this.noticeType){
        this.$Message.warning('请选择通知类型～');
        return;
      }
      if(this.noticeWay == '1' && this.noticeType){
        for(let i=0;i<this.templateFieldsArr.length;i++){
          if(!this.templateFieldsArr[i].value){
            this.$Message.warning(`请输入${this.templateFieldsArr[i].name}～`);
            return;
          }
        }

      }
      if(!this.workcheckAllGroup.length && !this.systemcheckAllGroup.length && !this.techcheckAllGroup.length && !this.statuscheckAllGroup.length && !this.extraUser.length){
        this.$Message.warning('请选择通知人员～');
        return;
      }
      let toUserIds = this.extraUser.map((item)=>{
        return item.user_id;
      }).join(',');

      let notToUserIds = this.removeUserList.map((item)=>{
        return item.user_id;
      }).join(',');
      let params = {
        send_type:this.noticeWay,
        to_user_ids:toUserIds,
        not_to_user_ids:notToUserIds,
        tech_direction:this.techcheckAllGroup.join(','),
        tech_system:this.systemcheckAllGroup.join(','),
        work_exp:this.workcheckAllGroup.join(','),
        verify_status:this.statuscheckAllGroup.join(','),
        status:status,
        type:this.noticeType
      };
      if(this.noticeWay == '1'){
        params.message = JSON.stringify(this.templateFieldsArr);
      }
      if(this.dataId){
        params.id = this.dataId;
      }

      this.api.user.teacherNoticeSave(params).then((res)=>{
        this.$Message.success('操作成功');
        this.$router.go(-1);
      })
    }
  }
}
</script>

<style scoped lang="scss">
.authentication-verify{
  margin: 20px;
  padding:20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;
  font-size: 14px;


  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .box{
      margin-bottom: 20px;
      margin-left:30px;
      padding-top: 15px;
      border: 1px solid #dcdee2;
      border-radius: 4px;
      .box-tit{
        margin-bottom: 10px;
        padding:0 30px;
        height: 36px;
        font-size: 14px;
        border-bottom: 1px solid #dcdee2;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        >span{
          color:#2d8cf0;
          cursor: pointer;
        }
      }
      .box-cont{
        margin: 0 30px;
        padding: 10px 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        >div{
          margin-right: 10px;
          margin-bottom: 10px;
          height: 32px;
          padding: 0 5px;
          border: 1px solid #2d8cf0;
          border-radius: 4px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color:#2d8cf0;
          >span{
            margin-right: 10px;

          }
        }
      }
    }
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 95px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }

      .notice-style{
        border: 1px solid #dcdee2;
        border-radius: 4px;
        padding: 30px;

        .notice-style-tit{
          margin-bottom: 10px;
        }
        .notice-style-item{
          margin-bottom: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          >p{
            margin-right: 5px;
            width: 60px;
            text-align: right;
          }
        }
      }
    }
  }

  .select-user{
    padding: 20px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-height: 300px;
    min-height: 100px;
    overflow-y: auto;

    .select-user-item{
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 5px;
      border: 1px solid #dcdee2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      height: 38px;
      >span{
        margin-right: 15px;
      }
    }
  }
}
.upload-cont-info-message{
  padding-top: 20px;
  margin: 0 auto 10px auto;
  width: 300px;
  text-align: left;
  line-height: 24px;
  .down-load{
    color:#2d8cf0;

  }
}
.top{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
